import AppRoutes from "./pages/AppRoutes";

function App() {
  return (
    <div className='font-montserrat tracking-wide'>
      <AppRoutes />
    </div >
  );
}

export default App;
