import React from 'react'
import Navbar from '../Index/Navbar'
import Hero from '../Index/Hero'
import Footer from '../Index/Footer'

const Home = () => {
    return (
        <div>
            <Navbar />
            <Hero />
            <Footer />
        </div>
    )
}

export default Home