export const orders = [
  { id: 1, customer: 'John Doe', product: 'Laptop', quantity: 1, price: 'Ksh 1200', date: '2024-07-31', status: 'Shipped' },
  { id: 2, customer: 'Jane Smith', product: 'Smartphone', quantity: 2, price: 'Ksh 800', date: '2024-07-30', status: 'Processing' },
  { id: 3, customer: 'Alice Johnson', product: 'Tablet', quantity: 1, price: 'Ksh 600', date: '2024-07-29', status: 'Delivered' },
  { id: 4, customer: 'Bob Brown', product: 'Headphones', quantity: 3, price: 'Ksh 150', date: '2024-07-28', status: 'Cancelled' },
  { id: 5, customer: 'Charlie Green', product: 'Smartwatch', quantity: 1, price: 'Ksh 250', date: '2024-07-27', status: 'Shipped' },
  { id: 6, customer: 'David Black', product: 'Camera', quantity: 1, price: 'Ksh 500', date: '2024-07-26', status: 'Processing' },
  { id: 7, customer: 'Eva White', product: 'Monitor', quantity: 2, price: 'Ksh 400', date: '2024-07-25', status: 'Delivered' }
];


export const deals = [
  { id: 1, name: 'Summer Sale', description: 'Up to 50% off on summer products.', price: 'Ksh 100', created_at: '2024-07-20' },
  { id: 2, name: 'Winter Discount', description: 'Special discounts for winter wear.', price: 'Ksh 150', created_at: '2024-07-18' },
  { id: 3, name: 'Black Friday', description: 'Biggest sale of the year.', price: 'Ksh 200', created_at: '2024-07-15' },
  { id: 4, name: 'Cyber Monday', description: 'Exclusive online deals.', price: 'Ksh 250', created_at: '2024-07-10' },
  { id: 5, name: 'New Year Bash', description: 'Celebrate with amazing discounts.', price: 'Ksh 300', created_at: '2024-07-05' },
  { id: 6, name: 'Back to School', description: 'Special prices for school supplies.', price: 'Ksh 120', created_at: '2024-07-01' },
  { id: 7, name: 'Holiday Deals', description: 'Special offers for the holiday season.', price: 'Ksh 180', created_at: '2024-06-25' },
];


export const initialDeals = [
  {
    id: 1,
    merchant_id: 101,
    title: 'Summer Fashion Sale',
    description: 'Up to 50% off on summer clothing and accessories.',
    image: 'path/to/image1.jpg',
    category: 'Clothing',
    price: 120.00,
    discount_percentage: 30.00,
    start_date: '2024-06-01',
    end_date: '2024-07-15',
    quantity_available: 150,
    terms_and_conditions: 'Discount applies to selected items only.'
  },
  {
    id: 2,
    merchant_id: 102,
    title: 'Electronics Extravaganza',
    description: 'Save up to 40% on the latest gadgets and electronics.',
    image: 'path/to/image2.jpg',
    category: 'Electronics',
    price: 299.99,
    discount_percentage: 40.00,
    start_date: '2024-07-01',
    end_date: '2024-08-01',
    quantity_available: 75,
    terms_and_conditions: 'Limited stock available.'
  },
  {
    id: 3,
    merchant_id: 103,
    title: 'Back to School Supplies',
    description: 'Get 20% off on all school supplies and stationery.',
    image: 'path/to/image3.jpg',
    category: 'Stationery',
    price: 15.00,
    discount_percentage: 20.00,
    start_date: '2024-08-01',
    end_date: '2024-09-01',
    quantity_available: 200,
    terms_and_conditions: 'Valid on purchases over $50.'
  },
  {
    id: 4,
    merchant_id: 104,
    title: 'Home Essentials Discount',
    description: 'Up to 25% off on home appliances and furniture.',
    image: 'path/to/image4.jpg',
    category: 'Home',
    price: 499.00,
    discount_percentage: 25.00,
    start_date: '2024-07-10',
    end_date: '2024-08-20',
    quantity_available: 50,
    terms_and_conditions: 'Excludes clearance items.'
  },
  {
    id: 5,
    merchant_id: 105,
    title: 'Fitness Gear Sale',
    description: 'Get 30% off on all fitness equipment and gear.',
    image: 'path/to/image5.jpg',
    category: 'Sports',
    price: 89.99,
    discount_percentage: 30.00,
    start_date: '2024-07-01',
    end_date: '2024-08-15',
    quantity_available: 100,
    terms_and_conditions: 'Discount applies to in-store purchases only.'
  },
  {
    id: 6,
    merchant_id: 106,
    title: 'Beauty Products Bonanza',
    description: 'Save 15% on all beauty and skincare products.',
    image: 'path/to/image6.jpg',
    category: 'Beauty',
    price: 45.00,
    discount_percentage: 15.00,
    start_date: '2024-07-05',
    end_date: '2024-08-05',
    quantity_available: 180,
    terms_and_conditions: 'While supplies last.'
  },
  {
    id: 7,
    merchant_id: 107,
    title: 'Gourmet Food Offer',
    description: 'Enjoy 10% off on all gourmet food items.',
    image: 'path/to/image7.jpg',
    category: 'Food',
    price: 25.00,
    discount_percentage: 10.00,
    start_date: '2024-07-10',
    end_date: '2024-07-30',
    quantity_available: 60,
    terms_and_conditions: 'No minimum purchase required.'
  },
  {
    id: 8,
    merchant_id: 108,
    title: 'Kids Toys Discount',
    description: 'Save 35% on selected toys for kids.',
    image: 'path/to/image8.jpg',
    category: 'Toys',
    price: 30.00,
    discount_percentage: 35.00,
    start_date: '2024-07-15',
    end_date: '2024-08-15',
    quantity_available: 120,
    terms_and_conditions: 'Valid on selected items only.'
  },
  {
    id: 9,
    merchant_id: 109,
    title: 'Luxury Watches Sale',
    description: 'Get up to 20% off on luxury watches.',
    image: 'path/to/image9.jpg',
    category: 'Jewelry',
    price: 899.00,
    discount_percentage: 20.00,
    start_date: '2024-07-01',
    end_date: '2024-08-31',
    quantity_available: 30,
    terms_and_conditions: 'Excludes limited editions.'
  },
  {
    id: 10,
    merchant_id: 110,
    title: 'Pet Supplies Sale',
    description: 'Save 25% on all pet food and accessories.',
    image: 'path/to/image10.jpg',
    category: 'Pets',
    price: 55.00,
    discount_percentage: 25.00,
    start_date: '2024-07-20',
    end_date: '2024-08-20',
    quantity_available: 90,
    terms_and_conditions: 'Online only.'
  },
  {
    id: 11,
    merchant_id: 111,
    title: 'Travel Gear Discounts',
    description: 'Up to 15% off on travel bags and accessories.',
    image: 'path/to/image11.jpg',
    category: 'Travel',
    price: 120.00,
    discount_percentage: 15.00,
    start_date: '2024-07-01',
    end_date: '2024-08-01',
    quantity_available: 80,
    terms_and_conditions: 'Discount applies at checkout.'
  },
  {
    id: 12,
    merchant_id: 112,
    title: 'Bookstore Clearance',
    description: 'Save up to 40% on clearance books.',
    image: 'path/to/image12.jpg',
    category: 'Books',
    price: 20.00,
    discount_percentage: 40.00,
    start_date: '2024-07-05',
    end_date: '2024-08-10',
    quantity_available: 150,
    terms_and_conditions: 'While supplies last.'
  },
  {
    id: 13,
    merchant_id: 113,
    title: 'Office Supplies Sale',
    description: 'Get 20% off on all office supplies.',
    image: 'path/to/image13.jpg',
    category: 'Office',
    price: 30.00,
    discount_percentage: 20.00,
    start_date: '2024-07-01',
    end_date: '2024-07-31',
    quantity_available: 200,
    terms_and_conditions: 'Valid in-store only.'
  },
  {
    id: 14,
    merchant_id: 114,
    title: 'Garden Tools Discount',
    description: 'Up to 25% off on garden tools and equipment.',
    image: 'path/to/image14.jpg',
    category: 'Garden',
    price: 80.00,
    discount_percentage: 25.00,
    start_date: '2024-07-10',
    end_date: '2024-08-10',
    quantity_available: 70,
    terms_and_conditions: 'Excludes outdoor furniture.'
  },
  {
    id: 15,
    merchant_id: 115,
    title: 'Health Supplements Sale',
    description: 'Save 30% on health supplements and vitamins.',
    image: 'path/to/image15.jpg',
    category: 'Health',
    price: 40.00,
    discount_percentage: 30.00,
    start_date: '2024-07-01',
    end_date: '2024-08-01',
    quantity_available: 100,
    terms_and_conditions: 'Discount applies to online orders only.'
  },
  {
    id: 16,
    merchant_id: 116,
    title: 'Kitchen Appliances Sale',
    description: 'Get up to 20% off on kitchen appliances.',
    image: 'path/to/image16.jpg',
    category: 'Appliances',
    price: 250.00,
    discount_percentage: 20.00,
    start_date: '2024-07-01',
    end_date: '2024-08-15',
    quantity_available: 60,
    terms_and_conditions: 'Valid in-store and online.'
  },
  {
    id: 17,
    merchant_id: 117,
    title: 'Jewelry Sale',
    description: 'Save 15% on selected jewelry pieces.',
    image: 'path/to/image17.jpg',
    category: 'Jewelry',
    price: 500.00,
    discount_percentage: 15.00,
    start_date: '2024-07-10',
    end_date: '2024-08-10',
    quantity_available: 40,
    terms_and_conditions: 'Excludes custom orders.'
  },
  {
    id: 18,
    merchant_id: 118,
    title: 'Smart Home Gadgets',
    description: 'Save up to 25% on smart home devices.',
    image: 'path/to/image18.jpg',
    category: 'Electronics',
    price: 180.00,
    discount_percentage: 25.00,
    start_date: '2024-07-15',
    end_date: '2024-08-15',
    quantity_available: 70,
    terms_and_conditions: 'Discount applies to new products only.'
  },
  {
    id: 19,
    merchant_id: 119,
    title: 'Camping Gear Sale',
    description: 'Get 20% off on all camping gear.',
    image: 'path/to/image19.jpg',
    category: 'Outdoor',
    price: 150.00,
    discount_percentage: 20.00,
    start_date: '2024-07-20',
    end_date: '2024-08-20',
    quantity_available: 50,
    terms_and_conditions: 'No returns on discounted items.'
  },
  {
    id: 20,
    merchant_id: 120,
    title: 'Music Equipment Discount',
    description: 'Save 30% on selected music instruments and equipment.',
    image: 'path/to/image20.jpg',
    category: 'Music',
    price: 350.00,
    discount_percentage: 30.00,
    start_date: '2024-07-01',
    end_date: '2024-08-01',
    quantity_available: 35,
    terms_and_conditions: 'Discount applies at checkout.'
  },
  {
    id: 21,
    merchant_id: 121,
    title: 'Pet Accessories Sale',
    description: 'Get 25% off on all pet accessories.',
    image: 'path/to/image21.jpg',
    category: 'Pets',
    price: 40.00,
    discount_percentage: 25.00,
    start_date: '2024-07-05',
    end_date: '2024-08-05',
    quantity_available: 85,
    terms_and_conditions: 'In-store only.'
  },
  {
    id: 22,
    merchant_id: 122,
    title: 'Seasonal Decor Sale',
    description: 'Save 15% on seasonal home decor items.',
    image: 'path/to/image22.jpg',
    category: 'Home',
    price: 60.00,
    discount_percentage: 15.00,
    start_date: '2024-07-10',
    end_date: '2024-08-10',
    quantity_available: 90,
    terms_and_conditions: 'Excludes clearance items.'
  },
  {
    id: 23,
    merchant_id: 123,
    title: 'Fitness Apparel Discount',
    description: 'Up to 20% off on fitness apparel and gear.',
    image: 'path/to/image23.jpg',
    category: 'Clothing',
    price: 70.00,
    discount_percentage: 20.00,
    start_date: '2024-07-15',
    end_date: '2024-08-15',
    quantity_available: 130,
    terms_and_conditions: 'Discount applies to new arrivals only.'
  },
  {
    id: 24,
    merchant_id: 124,
    title: 'Laptop Sale',
    description: 'Save up to 30% on selected laptops.',
    image: 'path/to/image24.jpg',
    category: 'Electronics',
    price: 799.00,
    discount_percentage: 30.00,
    start_date: '2024-07-20',
    end_date: '2024-08-20',
    quantity_available: 40,
    terms_and_conditions: 'While supplies last.'
  },
  {
    id: 25,
    merchant_id: 125,
    title: 'Kitchen Utensils Sale',
    description: 'Get 15% off on all kitchen utensils and gadgets.',
    image: 'path/to/image25.jpg',
    category: 'Kitchen',
    price: 40.00,
    discount_percentage: 15.00,
    start_date: '2024-07-25',
    end_date: '2024-08-25',
    quantity_available: 100,
    terms_and_conditions: 'Valid in-store and online.'
  }
];


export const fakeOrders = [
    {
        id: 1,
        merchant_id: 12,
        customer_id: 5,
        total: 850,
        discount_applied: 50,
        loyalty_points: 15,
        items: [
            {
                deal_id: 7,
                quantity: 1,
                price: 600,
                discount_applied: 30,
            },
            {
                deal_id: 15,
                quantity: 2,
                price: 200,
                discount_applied: 20,
            },
        ],
    },
    {
        id: 2,
        merchant_id: 18,
        customer_id: 8,
        total: 1200,
        discount_applied: 100,
        loyalty_points: 20,
        items: [
            {
                deal_id: 3,
                quantity: 3,
                price: 400,
                discount_applied: 50,
            },
            {
                deal_id: 9,
                quantity: 1,
                price: 800,
                discount_applied: 50,
            },
        ],
    },
    {
        id: 3,
        merchant_id: 6,
        customer_id: 12,
        total: 450,
        discount_applied: 30,
        loyalty_points: 10,
        items: [
            {
                deal_id: 11,
                quantity: 2,
                price: 200,
                discount_applied: 20,
            },
            {
                deal_id: 14,
                quantity: 1,
                price: 300,
                discount_applied: 10,
            },
        ],
    },
    {
        id: 4,
        merchant_id: 23,
        customer_id: 7,
        total: 950,
        discount_applied: 70,
        loyalty_points: 18,
        items: [
            {
                deal_id: 5,
                quantity: 1,
                price: 900,
                discount_applied: 70,
            },
        ],
    },
    {
        id: 5,
        merchant_id: 19,
        customer_id: 3,
        total: 530,
        discount_applied: 30,
        loyalty_points: 12,
        items: [
            {
                deal_id: 8,
                quantity: 3,
                price: 150,
                discount_applied: 30,
            },
            {
                deal_id: 10,
                quantity: 2,
                price: 250,
                discount_applied: 10,
            },
        ],
    },
    {
        id: 6,
        merchant_id: 9,
        customer_id: 22,
        total: 620,
        discount_applied: 40,
        loyalty_points: 14,
        items: [
            {
                deal_id: 2,
                quantity: 1,
                price: 300,
                discount_applied: 20,
            },
            {
                deal_id: 13,
                quantity: 2,
                price: 180,
                discount_applied: 20,
            },
        ],
    },
];


